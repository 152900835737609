import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;

  li {
    align-self: center;
    padding-right: 20px;
  }

  @media (max-width: 1240px) {
    li {
      align-self: center;
      padding-right: 0;
    }
  }

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 4px rgba(130, 130, 130, 0.25);
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100vh;
    width: 250px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
  }
`;


const RightNav = ({ open, setOpen }) => {
  return (
    <Ul onClick={() => setOpen(!open)} open={open} >
      <li > 
        <NavLink to="/" className="header-link">
          Home
        </NavLink>
      </li>
      <li>
        <NavLink to="/about" className="header-link">
          About
        </NavLink>
      </li>
      <li>
        <NavLink to="products" className="header-link">
          Products
        </NavLink>
      </li>
      <li>
        <NavLink to="contacts" className="header-link">
          Contact Us
        </NavLink>
      </li>
    </Ul>
  );
};

export default RightNav;
