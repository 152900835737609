import './header.scss'
import React from 'react'

import Navbar from './Nav/Navbar'


export default function Header() {
    return (
        <header className="header ">
            <Navbar/>
        </header>
    )
}
