import { Routes, Route } from "react-router-dom";
import About from "./components/main/about/about";
import Home from "./components/main/home/Home";
import Layout from "./components/Layout/Layout";
import NotFound from "./components/NotFout/NotFound";
import Products from "./components/main/products/products";
import Contacts from "./components/main/contacts/Contacts";
import ProductItem from "./components/main/products/components/tobaco-item/productItem";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />}/>
          <Route path="/products/:id" element={<ProductItem/>}/>         
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
