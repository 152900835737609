import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Burger from "./Burger";

const Nav = styled.nav`
  width: 100%;
  height: 70px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;

`;

const Navbar = () => {
  return (
    <Nav>
      <Link to="/" className="header-logo flex">
        <div className="logo-text">
          <div className="logo-text-item">Soma <span>LLC</span> </div>
        </div>
      </Link>
      <Burger />
    </Nav>
  );
};

export default Navbar;
