import React from "react";
import "./contacts.scss";

const Contacts = () => {
  return (
    <div>
      <div className="contact-us-bg"></div>
      <div className="wrapper contacts-section">
        <div>Contact Us</div>
        <div className="contact-info">
          <div className="contact-info-item">
            <div className="mail-svg"></div>
            <div>E-MAIL</div>
            <div>soma.llc@yahoo.com</div>
          </div>
          <div className="contact-info-item">
            <div className="adress-svg"></div>
            <div>ADDRESS</div>
            <div>3 GLINKA St</div>
          </div>
          <div className="contact-info-item">
            <div className="telephone-svg"></div>
            <div>PHONE</div>
            <div>+37477077770</div>
          </div>
          <div className="contact-info-item">
            <div className="postal-svg"></div>
            <div>INDEX</div>
            <div>YEREVAN 0005</div>
          </div>
        </div>
        <div className="contact-info-mobile">
          <div className="contact-info-item-mobile">
          <div className="contact-info-item">
            <div className="mail-svg"></div>
            <div>E-MAIL</div>
            <div>soma.llc@yahoo.com</div>
          </div>
          <div className="contact-info-item">
            <div className="adress-svg"></div>
            <div>ADDRESS</div>
            <div>3 GLINKA St</div>
          </div>
          </div>
          <div className="contact-info-item-mobile phone">
          <div className="contact-info-item">
            <div className="telephone-svg"></div>
            <div>PHONE</div>
            <div>+37477077770</div>
          </div>
          <div className="contact-info-item">
            <div className="postal-svg"></div>
            <div>INDEX</div>
            <div>YEREVAN 0005</div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
