import React from 'react'
import './footer.scss'

const Footer = () => {
  return (
    <footer className='footer'>
       <div className='wrapper'>
         <p className="footer-text">3 GLINKA St</p>
         <p className="footer-text">YEREVAN, INDEX - 0005, ARMENIA</p>
         <p className="footer-text">+37477077770</p>
         <p className="footer-text">soma.llc@yahoo.com</p>
         </div> 
       </footer>
  )
}

export default Footer