import React from "react";
import "./about.scss";

const About = () => {
  return (
    <div>
      <div className="about-us-bg mb-30">
        <div className="wrapper">
          <div className="about-us">
            <p>About us</p>
            <p>since 2016 </p>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div className="mb-10">
          <p className="about-us-title">Who We Are ?</p>
          <p className="about-us-content">
            Soma LLC was established in 2016. Since then we offer authentic
            tobacco,plastic materials for your business. We will help you to be more
            successful.
          </p>
        </div>
        <div className="flex about-us-items mb-30">
          <div className="oriental-tobacco"></div>
          <div className="about-us-info">
            <p className="about-us-title mb-10">Why Choose Us ? </p>
            <p className="about-us-content">
              We are a trusted and professional link between Manufacturer and
              Producer. You can be sure that you are provided with qualitative
              products. For us partners are priority.
            </p>
          </div>
          <div></div>
        </div>
        <div className="flex  mb-30 about-us-items">
          <div className="none"></div>
          <div className="about-us-info">
            <p className="about-us-title mb-10">How We Work? </p>
            <p className="about-us-content">
              Reliability and Stability, Customer care and Partnership
              Cooperation are the keys for us. Being trusted partner helps us to
              maintain long term relationships with our clients.
            </p>
          </div>
          <div className="virginna-tobacco"></div>
        </div>
      </div>
    </div>
  );
};

export default About;
