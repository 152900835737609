import React from "react";
import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
import "./home.scss";

const Home = () => {
  const navigate = useNavigate();
  const naviteToAbout = () => {
    navigate("about");
  };
  const naviteToProducts = () => {
    navigate("products");
  };

  return (
    <section>
      <div className="home-bg">
        <div className="wrapper">
          <div className="our-products">Welcome to Soma LLC</div>
        </div>
      </div>
      <div className="home-info-wrapper">
        <div className="home-info wrapper">
          <p>
          Soma LLC was established in 2016. <br /> Since then we offer authentic tobacco, plastic materials for your business. <br />Also we provide a wide range of equipment for the contribution of your business growth.  <br /> We will help you to be more successful.
          </p>
        </div>
      </div>
      <div className="wrapper home-navigation ">
        <div onClick={naviteToAbout} className="home-nav-item">
          <div className="about-us-link-img"></div>
          <div className="home-nav-item-content">
            <h2>About Us</h2>
            <p>
              Find out about the history, management and client base of our
              company.
            </p>
            <div>Learn more →</div>
          </div>
        </div>
        <div onClick={naviteToProducts} className="home-nav-item">
          <div className="our-products-link-img"></div>
          <div className="home-nav-item-content">
            <h2>Our Products</h2>
            <p>
            Know more about the process and our practices.
            </p>
            <div>Learn more →</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
