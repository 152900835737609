import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./productItem.scss";

const data = [
  {
    id: 1,
    title: "Polyamide",
    img: "polyamide",
    description:
      `Polyamide is a polymer held together with amide bonds.

       Fabrics such as wool, silk and nylon are all examples of polyamides — wool and silk are natural polyamides, whereas nylon is synthetic. Synthetic polyamide thermoplastics are important in engineering, clothing and packaging industries because they offer high performance at a reasonable cost.
      
       Polyamide is an engineering derived thermoplastic material which has excellent range of the mechanical properties, toughness, elasticity, chemical resistivity, high melting points, and moldability. Since, it has poor biodegradability so that it should be reformed and recycled to reuse many times in different applications.`,
  },
  {
    id: 2,
    title: "Aluminium foil",
    img: "aluminium",
    description:
      "Soma offers a wide range of aluminium paper laminates for cigarette inner liners, including materials printed with the customer’s logo either on the aluminium side or on the paper side. Aluminium foil with different grammage and thickness of aluminium coating, we can offer you also foil of different colours and with logo in accordance with your design",
  },
  {
    id: 3,
    title: "Cigarette Paper",
    img: "cigarette-paper",
    description:
      "Available in wood, flax or mix from 18 gsm up to 40 gsm with features that meet international quality standards.Wild range of cigarette papers and plugwrap papers with different grammage and dimensions, coloured cigarette paper.",
  },
  {
    id: 4,
    title: "Printed inner frames",
    img: "printed-frame",
    description:
      "Cardboard of wild range of thickness and dimensiones in bobbins (from 180 g/m2 up to 260 g/m2), coloured cardboard",
  },
  {
    id: 5,
    title: "Polypropylene",
    img: "polypropylene",
    description:
      "Our product range of films for the tobacco industry includes standard films, heat shrinkable films, high-shrinkable films, low sealing temperature films (acrylic coated film subsitution) for clear wrap applications and printable films.",
  },
  {
    id: 6,
    title: "Polyethylene",
    img: "polyethylene",
    description:
      "It is a polymer, primarily used for packaging. Polyethylene consists of nonpolar, saturated, high-molecular-weight hydrocarbons. Most LDPE, MDPE, and HDPE grades have excellent chemical resistance, meaning that they are not attacked by strong acids or strong bases and are resistant to gentle oxidants and reducing agents. Crystalline samples do not dissolve at room temperature.",
  },
  {
    id: 7,
    title: "Tobacco Products",
    img: "tobacco-product",
    description:
      "Tobaco is a plant with leaves that have high levels of the addictive chemical nicotine. After harvesting, tobacco leaves are cured, aged, and processed in various ways. We offer the following types of tobaco: Flue Cured Virginia Tobacco, Burley Tobacco, Oriental Tobacco, Reconstituted Tobaco, FCV stems / Burley stems, FCV Scraps.For more information, you can contact us.",
  },
  {
    id: 8,
    title: "Equipment",
    img: "equipment",
    description:
      "Every business operation can be implemented easier and more professional with the good equipment. Soma LLC is also an importer of business equipments. For more information feel free to contact us.",
  },
];

const ProductItem = () => {
  const { id } = useParams();
  const [myData, setMyData] = useState({});

  useEffect(() => {
    const singleData = data.find((item) => item.id === +id);
    setMyData(singleData);
  }, [id]);

  return (
    myData && (
      <>
        <div className="products-bg">
          <div className="wrapper">
            <div className="our-products">Our Products</div>
          </div>
        </div>
        <div className="wrapper">
          <div className="product">
            <div className={myData.img}></div>
            <div>
              <div>{myData.title}</div>
              <div> {myData.description}</div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default ProductItem;
