import React from "react";
import { Link } from "react-router-dom";
// import { useLocation } from 'react-router-dom'
// import { Outlet } from 'react-router-dom'

// import Tobacco from './components/tobacco-product/Tobacco'
import "./products.scss";

const data = [
  { id: 1, title: "Polyamide", img: "polyamide" },
  { id: 2, title: "Aluminium foil", img: "aluminium" },
  { id: 3, title: "Cigarette Paper", img: "cigarette-paper" },
  { id: 4, title: "Printed inner frame", img: "printed-frame" },
  { id: 5, title: "Polypropylene", img: "polypropylene" },
  { id: 6, title: "Polyethylene", img: "polyethylene" },
  { id: 7, title: "Tobacco Products", img: "tobacco-product" },
  { id: 8, title: "Equipment", img: "equipment" },
  { id: 8, title: " ", img: " " },

];

const Products = () => {


  return (
    <section className="products">
      <div className="products-bg">
        <div className="wrapper">
          <div className="our-products">Our Products</div>
        </div>
      </div>
      <div className="products-container flex wrapper">
        {data.map((item) => (
          <Link
            className="product-item"
            key={item.id}
            to={`/products/${item.id}`}
          >
            <div className={`${item.img} img-item`}></div>
            <div className="">{item.title}</div>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default Products;
